var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('template-dialog', {
    attrs: {
      "context": _vm.context,
      "to": _vm.to,
      "from": [_vm.partner],
      "refs": _vm.mailRefs
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }